.title-def {
  font-weight: 400;
  color: rgb(43, 124, 116);

  &_med {
    color: rgb(22, 62, 58);
    font-size: 20px;
  }

  &_sec {
    font-size: 20px;
  }

  &_sec2 {
    font-size: 25px;
    color: rgb(22, 62, 58);
  }

  &_sec3 {
    font-size: 28px;
  }
}

.links-def {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__el {
    margin-right: 20px;

    &.active {
      .links-def__link {
        color: rgb(43, 124, 116);
      }
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &__link {
    display: block;
    line-height: 1;
    font-size: 17px;
    color: rgb(22, 62, 58);
    font-weight: 700;
    transition: .3s;

    &:hover {
      color: rgb(43, 124, 116);
    }
  }
}

.text-info {
  color: rgb(96, 94, 94);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.28;
}

.wrap-article {
  text-align: justify;
}

.btn-def {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 7px 35px;
  background: rgba(182, 232, 227, 0.6);
  box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px 0px,
    inset 0 5px 23px -10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: rgb(96, 94, 94);
  transition: .3s;

  &:hover {
    color: #fff;
  }
}

.text-info-2 {
  font-size: 16px;
}

.wrap-article-2 {
  font-size: 18px;
  text-align: justify;
  line-height: 1.34;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(43, 124, 116);
    font-weight: 600;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    color: #000;
    font-weight: 700;
  }

  p {
    min-height: 20px;
  }

  ul {
    padding-left: 31px;

    li {
      display: list-item;
      list-style-type: disc;
    }
  }

  a {
    text-decoration: underline;
    color: #000;
    line-height: 1.9;
  }
}

strong {
  font-weight: 600;
}

.color-g {
  color: rgb(43, 124, 116);
}

.btn-def-3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  background: rgb(141, 209, 202);
  color: #fff;
  padding: 10px 11px;
}