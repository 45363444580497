@font-face {
  font-family: 'Helvetica';
  src: url('../../fonts/helvetica/subset-Helvetica_cyr-BoldOblique.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica_cyr-BoldOblique.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Bold Oblique';
  src: url('../../fonts/helvetica/subset-Helvetica-BoldOblique.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-BoldOblique.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Oblique';
  src: url('../../fonts/helvetica/subset-Helvetica-Oblique.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-Oblique.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../fonts/helvetica/subset-Helvetica-Bold.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Light Oblique';
  src: url('../../fonts/helvetica/subset-Helvetica-LightOblique.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../fonts/helvetica/subset-Helvetica-Light.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../fonts/helvetica/subset-Helvetica-Regular.woff2') format('woff2'),
    url('../../fonts/helvetica/subset-Helvetica-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}