html {
	overflow-x: hidden;
}

body {
	font-family: 'Helvetica', 'Arial', sans-serif;
	padding: 0;
	margin: 0;
	line-height: 1.26;
	overflow-x: hidden;
	font-size: 18px;
	font-weight: 400;
	background: #fff;
}

a {
	font-family: 'Helvetica', Arial, sans-serif;
	display: inline-flex;
}

input {

	&,
	&::placeholder {
		font-family: 'Helvetica', 'Arial', sans-serif;
	}
}

img {}

.wrapper-def {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.container-def {
	padding: 0 20px;
	margin: 0 auto;
	width: 100%;
	position: relative;
	z-index: 10;
	max-width: 1036px;
}

.top-menu {
	background: rgba(182, 232, 227, 0.56);
	border-bottom: 1px solid rgb(47, 46, 46);
	box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 6px 0px;

	&__wrap {
		padding: 21px 0;
	}

	&__content {
		display: flex;
		align-items: center;
	}

	&__imgs {
		margin-right: 24px;
	}

	&__logo {
		margin-top: 14px;
	}

	&__links {
		flex: 1;
		margin-left: 92px;
		align-self: flex-end;
	}
}

.sec-content {
	flex: 1;

	&__wrap {
		padding: 80px 0;
	}

	&_2 {
		.sec-content__wrap {
			padding: 30px 0;
			margin-left: auto;
			margin-right: auto;
			max-width: 828px;
			width: 100%;
		}
	}

	&_3 {
		.sec-content__wrap {
			padding: 42px 0;
			margin-left: auto;
			margin-right: auto;
			max-width: 911px;
			width: 100%;
		}
	}
}

.home-info {
	display: flex;

	&__text {
		width: 250px;
		display: flex;
		flex-direction: column;
	}

	&__btn {
		margin-left: auto;
	}

	&__products {
		width: 337px;
		margin-left: 100px;
	}

	&__contact {
		margin-left: auto;
		width: 217px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.sec-footer {
	background: rgba(182, 232, 227, 0.56);
	border-top: 2px solid #000;
	margin-top: auto;

	&__wrap {
		padding: 20px 0;
	}
}

.product-info {
	&__top {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		&-left {
			max-width: 700px;
		}
	}

	&__title {
		line-height: 1.4;
	}

	&__content {
		max-width: 779px;
		margin-bottom: 40px;
	}

	&__block {

		&-title {
			margin-bottom: 20px;
		}
	}
}

.about-info {
	display: flex;
	justify-content: space-between;

	&__title {
		margin-bottom: 21px;
	}

	&__left {
		width: 550px;
		margin-right: 40px;
	}

	&__gal {
		width: 360px;

		&-img {
			width: 100%;
			height: auto;
			margin-bottom: 30px;
			border-radius: 5px;
		}
	}
}

.contacts-block {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 520px;

	&__title {
		margin-bottom: 20px;
	}

	&__text {
		text-align: left;
	}

	&__form {
		margin-top: 30px;
		display: flex;
		flex-direction: column;

		&-btn {
			align-self: flex-end;
		}

	}
}