button {
	border: 0;
	box-shadow: none;
	padding: 0;
	background: transparent;
	line-height: 1;
	text-align: left;
}

img {
	object-fit: contain;
}

.imgs-def {
	display: flex;
	flex-wrap: wrap;
	font-size: 0;
	width: 100px;

	&__img {
		display: block;
		object-fit: cover;
		width: 50%;
		height: 50px;
	}
}

.logo-def {
	font-size: 0;
	flex: none;

	&__img {
		flex: none;
		height: auto;
		width: 213px;
		height: 46px;
	}
}

.products {
	display: flex;
	flex-wrap: wrap;
	margin-left: -7px;
	margin-right: -7px;
	margin-bottom: -14px;



	&__el {
		width: 50%;
		padding: 0 7px;
		margin-bottom: 14px;

		&:nth-child(2) {
			.products-el {
				&::after {
					background: rgba(182, 232, 227, 0.39);
				}
			}
		}

		&:nth-child(3) {
			.products-el {
				&::after {
					background: rgba(158, 140, 40, 0.6);
				}
			}
		}

		&:nth-child(4) {
			.products-el {
				&::after {
					background: rgba(160, 160, 159, 0.7);
				}
			}
		}
	}

	&-el {
		display: block;
		position: relative;
		height: 0;
		padding-bottom: 100%;
		border-radius: 5px;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(233, 159, 134, 0.42);
			z-index: 2;
			transition: .3s;
			opacity: 1;
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			object-fit: cover;
			user-select: none;
		}

		&__content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			z-index: 10;
			padding: 20px;
		}

		&__title {
			font-size: 18px;
			color: rgb(47, 46, 46);
			font-weight: 700;
		}

		&:hover {
			&::after {
				opacity: 0;
			}
		}
	}
}

.contact-block {
	&__title {
		font-size: 18px;
		color: rgb(43, 124, 116);
	}

	&__text {
		font-size: 18px;
		color: rgb(96, 94, 94);
	}
}

.btn-burger {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	cursor: pointer;
	width: 50px;
	height: 50px;
	border: 1px solid #000;
	background: #fff;
}

.products-info {
	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 47px;
	}
}

.products-imgs {
	display: flex;
	font-size: 0;

	&-el {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		width: 65px;
		height: 63px;
		padding: 5px;
		background: #fff;
		box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 0px;

		&__img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}

.links-products {
	display: flex;
	flex-wrap: wrap;
	margin-left: -70px;
	margin-right: -70px;

	&_2 {
		margin-left: -20px;
		margin-right: -20px;

		.links-products__el {
			width: 33.333333%;
			padding: 0 20px;
		}
	}

	&__side {
		width: 50%;
		padding: 0 70px;

		.links-products-el {
			margin-bottom: 40px;
		}
	}

	&__el {
		width: 50%;
		padding: 0 70px;
		margin-bottom: 60px;

		.links-products-el {
			height: 100%;
		}
	}

	&__title {
		margin-bottom: 20px;
	}

	&-el {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px 0px;
		background: rgba(204, 204, 204, 0.58);
		padding: 20px 23px;
		min-height: 113px;
		border-radius: 5px;
		transition: .3s;

		&__text {
			color: rgb(43, 124, 116);
			font-weight: 700;
			font-size: 18px;
			transition: .3s;
		}

		&:hover {
			background: rgb(182, 232, 227);

			.links-products-el__text {
				color: #fff;
			}
		}

		&_2 {
			.links-products-el__text {
				font-weight: 700;
				font-size: 22px;
			}

			&:hover {
				.links-products-el__text {
					color: rgb(43, 124, 116);
				}
			}
		}
	}
}

.img-dec {
	width: 150px;
	height: 120px;
	padding: 5px;
	background: #fff;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 0px;
	opacity: 1;
	transition: .3s;

	&:hover {
		opacity: 0.8;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		display: block;
	}
}