.noscroll {
	overflow: hidden;
}

.overlay-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
}

.btn-close {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	cursor: pointer;
	user-select: none;
	width: 22px;
	height: 22px;

	path {
		transition: .3s;
	}

	&:hover {
		path {
			fill: #7381FB;
		}
	}
}

.overlay {
	background: #000000;
	opacity: 0.67;
}


.modal-def {
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 999;
	display: none;

	&__wrap {
		width: 100%;
		min-height: 100%;
		overflow: hidden;
		padding: 20px 15px;


		position: relative;
		display: flex;
		align-items: center;

		// padding-top: 0 !important;
		// align-items: flex-start;

		justify-content: center;
	}

	&__content {
		position: relative;
		z-index: 10;
		width: 100%;
	}

	&__close {
		cursor: pointer;
	}

	&__overlay {
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
		z-index: 1;
	}
}

.modal-content {
	width: 100%;
	max-width: 556px;
	padding: 40px 50px;
}